<template>
  <ContentWrapper id="config">
    <div class="content-heading">收費配置</div>

    <el-form ref="configForm" :model="configForm" label-width="13rem"  >
      <!--<div class="card-deck">-->
      <div class="card card-default">
          <!--<div class="card-header">基本設置</div>-->
          <div class="card-body">

            <el-form-item label="單件申報價格上限">
              <el-input v-model="configForm.single_limit_price" style="width: 150px;" />（HKD）
            </el-form-item>
            <el-form-item label="多件申報價格上限">
              <el-input v-model="configForm.multiple_limit_price" style="width: 150px;" />（HKD）
            </el-form-item>

            <el-form-item label="包裹重量最大值">
              <el-input v-model="configForm.spx_max_weight" style="width: 150px;" />（KG）
              文件重量最大值
              <el-input v-model="configForm.doc_max_weight" style="width: 150px;" />（KG）
            </el-form-item>
            <el-form-item label="三邊和最大值">
              <el-input v-model="configForm.max_size" style="width: 150px;" />（CM）
              最長邊小於<el-input v-model="configForm.max_longest_size" style="width: 150px;" />
            </el-form-item>
            <el-form-item label="材重比例">
              <el-input v-model="configForm.volume_percent" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="旺季時間段">
              <ul style="min-height: 60px">
                <li v-for="(date, key) in configForm.peak_seasons" :key="key">
                  {{ date[0] }} 至 {{ date[1] }}
                  <el-button class="ml-3" size="mini" type="primary" @click="delDate(key)">刪除</el-button>

                </li>
              </ul>
              <el-date-picker
                  class="w80"
                  v-model="peakSeason"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <el-button type="primary" :disabled="!Boolean(peakSeason && peakSeason.length)" @click="addDate">添加旺季時間段</el-button>
            </el-form-item>

            <el-form-item label="旺季附加費">
              首重（KG）
              <el-input v-model="configForm.peak_season_weight" style="width: 150px;" />
              價格（HKD）
              <el-input v-model="configForm.peak_season_fee" style="width: 150px;" />
              ，之後每增重（KG）：
              <el-input v-model="configForm.peak_season_fee_add_weight" style="width: 150px;" />
              增加收費價格
              <el-input v-model="configForm.peak_season_fee_add_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="旺季燃油附加費">
              <el-input v-model="configForm.peak_season_fuel_surcharge" style="width: 150px;" />%
              <!--%，（運費+旺季附加費）*百分比-->
            </el-form-item>
            <el-form-item label="燃油附加費">
              <el-input v-model="configForm.fuel_surcharge" style="width: 150px;" />%
              <!--%，（運費+旺季附加費）*百分比-->
            </el-form-item>

            <el-form-item label="住宅附加費">
              首重（KG）
              <el-input v-model="configForm.residential_surcharge" style="width: 150px;" />
              價格（HKD）
              <el-input v-model="configForm.residential_surcharge_fee" style="width: 150px;" />
              ，之後每增重（KG）：
              <el-input v-model="configForm.residential_surcharge_add_weight" style="width: 150px;" />
              增加收費價格
              <el-input v-model="configForm.residential_surcharge_add_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="偏遠附加費">
              首重（KG）
              <el-input v-model="configForm.remote_surcharge_weight" style="width: 150px;" />
              價格（HKD）
              <el-input v-model="configForm.remote_surcharge_fee" style="width: 150px;" />
              ，之後每增重（KG）：
              <el-input v-model="configForm.remote_surcharge_add_weight" style="width: 150px;" />
              增加收費價格
              <el-input v-model="configForm.remote_surcharge_add_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="排倉費">
              首重（KG）
              <el-input v-model="configForm.disposal_weight" style="width: 150px;" />
              價格（HKD）
              <el-input v-model="configForm.disposal_fee" style="width: 150px;" />
              ，之後每增重（KG）：
              <el-input v-model="configForm.disposal_add_weight" style="width: 150px;" />
              增加收費價格
              <el-input v-model="configForm.disposal_add_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="單邊長">
              長於
              <el-input v-model="configForm.single_side_max" style="width: 150px;" />
              CM，加收（HKD）
              <el-input v-model="configForm.single_side_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="三邊總和">
              大於
              <el-input v-model="configForm.three_sides_sum" style="width: 150px;" />
              CM，加收（HKD）
              <el-input v-model="configForm.three_sides_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="單件重量">
              大於
              <el-input v-model="configForm.piece_weight" style="width: 150px;" />
              KG，加收（HKD）
              <el-input v-model="configForm.piece_fee" style="width: 150px;" />
            </el-form-item>

            <el-form-item label="預計時效">
              <el-input v-model="configForm.estimated_time"  />
            </el-form-item>

          </div>
        </div>
      <!--</div>-->

      <div class="mt-3 text-center">
        <el-button type="primary" @click="save()">保存</el-button>
      </div>

    </el-form>

  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { getLoadingInstance } from '@/helpers';
// import InputTag from 'vue-input-tag';
import { mapState } from 'vuex';

export default {
  name: 'Config',
  mounted() {
    // const loading = getLoadingInstance();
    // this.$api.config.get().then(({ data: response }) => {
    //   this.configForm = { ...response };
    //   // console.log(response);
    //   // _.forEach(this.configForm, (value, key) => {
    //   //   this.$set(this.configForm, key, response[key]);
    //   // });
    // }).finally(() => {
    //   loading.close();
    // });
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        if (id) {
          this.id = id;
          const loading = getLoadingInstance();
          this.$api.group.get(id)
            .then(({ data: response }) => {
              if (Object.keys(response.data.config).length) {
                this.configForm = response.data.config;
              }
            })
            .catch((e) => {
              this.$notify.error({
                title: e.response.data.message,
              });
            })
            .finally(() => {
              loading.close();
            });
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      id: null,
      peakSeason: [],
      website_search_suggests: [],
      configForm: {
        peak_seasons: [],
        volume_percent: 0,
        single_limit_price: 0,
        multiple_limit_price: 0,
        max_size: 0,
        max_longest_size: 0,
        max_weight: 0,
        spx_max_weight: 0,
        doc_max_weight: 0,
        peak_season_weight: 0,
        peak_season_fee: 0,
        peak_season_fuel_surcharge: 0,
        peak_season_fee_add_weight: 0,
        peak_season_fee_add_fee: 0,
        fuel_surcharge: 0,
        residential_surcharge: 0,
        residential_surcharge_fee: 0,
        residential_surcharge_add_weight: 0,
        residential_surcharge_add_fee: 0,
        remote_surcharge_weight: 0,
        remote_surcharge_fee: 0,
        remote_surcharge_add_weight: 0,
        remote_surcharge_add_fee: 0,
        disposal_weight: 0,
        disposal_fee: 0,
        disposal_add_weight: 0,
        disposal_add_fee: 0,
        single_side_fee: 0,
        single_side_max: 0,
        three_sides_sum: 0,
        three_sides_fee: 0,
        piece_weight: 0,
        piece_fee: 0,

      },
    };
  },
  computed: {
    ...mapState({
      // config: state => state.app.config,
    }),
  },
  methods: {
    addDate() {
      this.configForm.peak_seasons.push(this.peakSeason);
      this.peakSeason = [];
    },
    delDate(key) {
      this.configForm.peak_seasons.splice(key, 1);
    },
    save() {
      const params = _.cloneDeep(this.configForm);
      const loading = getLoadingInstance();
      this.$api.group.save({ config: params, id: this.id }).then(() => {
        this.$notify.success({
          title: '配置已保存',
        });
      }).catch((e) => {
        this.$notify.error({
          title: '保存失敗',
          message: e.response.data.message,
        });
      }).finally(() => {
        loading.close();
      });
    },
  },
  components: {
    // InputTag,
  },
};
</script>
<style lang="scss">

  .el-range-separator {
    padding: 0!important;
  }
</style>
